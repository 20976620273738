/* @keyframes sway {
    0%, 100% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
  }
  @keyframes float {
    0% { transform: translate(0px, 0px) scale(1); }
    10% { transform: translate(-10px, -10px) scale(1.05); }
    20% { transform: translate(10px, -20px) scale(1); }
    30% { transform: translate(-10px, 10px) scale(0.95); }
    40% { transform: translate(20px, 0px) scale(1); }
    50% { transform: translate(0px, -15px) scale(1.05); }
    60% { transform: translate(-15px, 10px) scale(1); }
    70% { transform: translate(10px, 20px) scale(0.95); }
    80% { transform: translate(-20px, 0px) scale(1); }
    90% { transform: translate(10px, -10px) scale(1.05); }
    100% { transform: translate(0px, 0px) scale(1); }
  } */
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.01);
    }
    100% {
      transform: scale(1);
    }
  }
  .bubble {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    position: relative;
    animation: pulse 2s infinite ease-in-out;
    cursor: pointer; /* Indicates the bubble is clickable */
    transition: transform 0.3s ease-in-out; /* Smooth transition for transform changes */
  }
  .bubble:hover {
    transform: scale(1.1); /* Scale up the bubble slightly on hover */
  }
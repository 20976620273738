.loading-box {
    width: 380px;
    height: 200px;
    background-color: #e0e0e0; /* Lighter grey for the loading box */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  
  .loading-animation {
    position: absolute;
    height: 100%;
    width: 100px; /* Width of the animated bar */
    background-color: rgba(255, 255, 255, 0.3); /* Slightly more transparent for smoother effect */
    animation: slide 2s infinite ease-in-out; /* Increased duration and added easing for smoothness */
  }
  
  @keyframes slide {
    0%, 100% {
      transform: translateX(-150%); /* Start and end outside the box for smoother transition */
    }
    50% {
      transform: translateX(250%); /* Ensure the bar moves across the entire box */
    }
  }
.firebase-emulator-warning {
  display: none !important;
}
body {
  background-color: #ffffff;
  font-family: 'Outfit', Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}
a {
  font-family: "Outfit", Arial, Helvetica, sans-serif;
  text-decoration: underline;
  color: #17CF8B;
}
a.button {
  text-decoration: none;
}
a:visited {
  color: #17CF8B;
}
a:hover {
  text-decoration: none;
}
.dropdown {
  background-color: #ffffff;
  padding:10px;
  color: #222222;
  border-radius: 4px;
  border-width:1px;
  border-color: #CCCCCC;
  border-style: solid;
}
.dropdown-choices-parent {
  position: absolute;
  left: 0;
  z-index: 3;
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  border-width:1px;
  border-color: #CCCCCC;
  border-style: solid;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.dropdown-choice {
  padding:10px;
  color:#222222;
}
.dropdown-choice:hover {
  background-color: #e9e9e9;
}
.image-button {
  padding:10px;
  width:100%;
  font-size:1.0em;
  box-sizing: border-box;
  font-family: 'Outfit';
  font-weight: 300; /* Light weight */
  border-radius: 4px;
  border-width:1px;
  border-color: #CCCCCC;
  border-style: solid;
  height: 0;
  padding-top: 40%; /* 16:9 Aspect Ratio */
  position: relative; /* Make it a relative container for absolute children */
}
.image-button-content {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  top: 25;
  left: 0;
  bottom: 0;
  right: 0;
  width:100%;
  height: 100%;
}
input {
  padding:10px;
  padding-top:25px;
  width:100%;
  font-size:1.0em;
  box-sizing: border-box;
  font-family: 'Outfit';
  font-weight: 300; /* Light weight */
  border-radius: 4px;
  border-width:1px;
  border-color: #CCCCCC;
  border-style: solid;
}
textarea {
  width:100%;
  font-size:1.0em;
  padding:10px;
  box-sizing: border-box;
  font-family: 'Outfit';
  font-weight: 300; /* Light weight */
  border-radius: 4px;
  border-width:1px;
  border-color: #CCCCCC;
  border-style: solid;
  min-height:200px
}
.dropdown-menu {
  position: absolute;
  background-color: white;
  color: #222222;
  border-radius: 4px;
  border-width:1px;
  border-color: #CCCCCC;
  border-style: solid;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 3;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}
.loading-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top-color: #17CF8B;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.loading-icon-dark {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #2b2b2b;
  border-top-color: #17CF8B;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.loading-icon-small {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid #ffffff;
  border-top-color: #17CF8B;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.loading-icon-small-dark {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid #2b2b2b;
  border-top-color: #17CF8B;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.truncate-multiline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines you want */
  overflow: hidden;
  text-overflow: ellipsis;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  z-index: 1000;
}
.dropdown-menu div:hover {
  background-color: #f1f1f1;
}
.input-title {
  position:relative;
  z-index:2;
  top:20px;
  left:10px;
  margin-top:-10px;
}
button {
  background-color: #17CF8B;
  font-weight:400;
  border-width: 0px;
  color:#ffffff;
  border-radius: 4px;
}
button.disabled {
  background-color: #acacac;
  font-weight:400;
  border-width: 0px;
  color:#ffffff;
  border-radius: 4px;
}
button.disabled:hover {
  background-color: #acacac;
}
button:hover {
  background-color: #1bec9f;
  cursor: pointer
}
button.unselected {
  background-color: #A3D1C2;
}
button.unselected:hover {
  background-color: #6ad0ae;
}
button.nav {
  background-color: #000;
  font-weight: 600;
  text-decoration: none;
  border-radius: 0px;
  font-size: 1.2em;
  min-width: 2.8em; /* Minimum width to start as a square */
  padding:10px;
}
button.nav:hover {
  background-color: #17CF8B;
}
button.small {
  font-size: 0.8em;
  min-width: 1.8em; /* Minimum width to start as a square */
  padding:5px;
}
button.medium {
  font-size: 1.0em;
  min-width: 2.8em; /* Minimum width to start as a square */
  padding:10px;
}
button.large {
  font-size: 1.5em;
  min-width: 2.8em; /* Minimum width to start as a square */
  padding:15px;
}
.content-item-wrapper {
  padding:10px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.icon-buttons {
  fill:#17CF8B
}
.icon-buttons.red {
  fill: #ff0000
}
.session-builder-numbers {
  width: 30px;
  height: 30px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.spinning {
  animation: spin 1s linear infinite;
}
@font-face {
  font-family: 'Outfit';
  src: url('./assets/fonts/Outfit-Bold.ttf') format('truetype');
  font-weight: 500; /* bold weight */
}
@font-face {
  font-family: 'Outfit';
  src: url('./assets/fonts/Outfit-Regular.ttf') format('truetype');
  font-weight: 400; /* Normal weight */
}
@font-face {
  font-family: 'Outfit';
  src: url('./assets/fonts/Outfit-Light.ttf') format('truetype');
  font-weight: 300; /* Light weight */
}
ul {
  color:#222222;
  padding:0px;
  margin-left:20px
}
li {
  padding:10px;
}
h1 {
  font-family: 'Outfit', Arial, Helvetica, sans-serif;
  font-size: 1.5em;
  font-weight: 300;
  color: #222222;
  margin: 0px;
  padding: 0px;
}
h2 {
  font-family: 'Outfit', Arial, Helvetica, sans-serif;
  font-size: 1.2em;
  font-weight: 300;
  color: #222222;
  margin: 0px;
  padding: 0px;
} 
h3 {
  font-family: 'Outfit', Arial, Helvetica, sans-serif;
  font-size: 1.0em;
  font-weight: 300;
  color: #222222;
  margin: 0px;
  padding: 0px;
}
h4 {
  font-family: 'Outfit', Arial, Helvetica, sans-serif;
  font-size: 0.8em;
  font-weight: 300;
  color: #393939;
  margin: 0px;
  padding: 0px;
}
/* Styling for Chrome, Edge */
input[type='range']::-webkit-slider-runnable-track {
  background: rgb(102, 102, 102); /* Track color */
  height:5px; /* Height of the track */
  border-radius: 4px;
  margin-bottom: 7px;
}

/* Styling for the thumb for Chrome, Edge */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px; /* Width of the thumb */
  height: 15px; /* Height of the thumb */
  background: #17CF8B; /* Thumb color */
  cursor: pointer;
  border-radius: 50%; /* Circular thumb */
  /* Center the thumb on the track */
  margin-top: -5px; /* Half the difference of (thumb height - track height) */
}


/* Styling for Firefox */
input[type='range']::-moz-range-track {
  background:  rgb(102, 102, 102); ; /* Track color */
}

/* Styling for the thumb for Firefox */
input[type='range']::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #17CF8B;
  cursor: pointer;
  border-radius: 50%;
  border: none;
}

input[type='range']::-moz-range-progress {
  background: #17CF8B; /* Highlighted part color */
}

/* General styles for the range input */
input[type='range'] {
  -webkit-appearance: none; /* Removes default webkit styles */
  appearance: none;
  width: 100%; /* Width of the slider */
  height: 5px; /* Height of the track */
  background: #000; /* Track color */
  border:0px;
  outline: none; /* Removes the outline */
  opacity: 0.7; /* Partial transparency */
  /* Adjust padding to prevent the thumb from cutting off */
  padding: 0;
  margin: 0px 0; /* Additional space for the thumb */
}

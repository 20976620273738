.striped-container {
    display: flex;
    height: 100px; /* Adjust as needed */
    position: relative;
  }
  
  .stripe {
    height: 100%;
    display: inline-block;
  }
/* Overlay */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2); /* Adjust transparency as needed */
    z-index: 1; /* Ensure it's above stripes but below content */
  }
  
  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2; /* Higher than overlay to keep content visible */
  }
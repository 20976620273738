.background-video-container {
    position: relative;
    overflow: hidden;
  }
  
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures video covers the whole container */
  }
  
  .overlay-content {
    position: absolute;
    /* max-width:500px; */
    top: 0;
    height:100%;
    left: 10%; /* Align to the far left */
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    border-radius: 10px;
    text-align: left;
    color: white; /* Adjust text color as needed */
  }

  .logo {
    max-width: 100px; /* Adjust logo size as needed */
    margin-bottom: 20px;
  }
  h1.hero {
    font-family: 'Outfit', Arial, Helvetica, sans-serif;
    font-size: 2.5em;
    font-weight: 600;
    color: #fff;
    margin: 0px;
    padding: 0px;
  }
  h2.hero {
    font-family: 'Outfit', Arial, Helvetica, sans-serif;
    font-size: 2.0em;
    font-weight: 400;
    color: #e5e5e5;
    margin: 0px;
    padding: 0px;
  }
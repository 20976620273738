.footer {
    width: 100%;
    background-color: black;
    color: white;
    text-align: center;
    padding: 20px 0;
  }
  
  .footer-links {
    margin-bottom: 20px;
  }
  
  .footer-links a {
    color: white;
    margin: 0 15px;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .footer-copy {
    font-size: 14px;
  }
.toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    padding: 10px 20px;
    border-radius: 4px;
    color: white;
    background-color: black;
    z-index: 1000;
    transition: opacity 0.3s;
    transform: translate(-50%, 100%); /* Start off-screen */
    animation: slideUp 0.5s ease forwards;
  }
  
  .toast.success {
    background-color: #17CF8B;
  }
  
  .toast.error {
    background-color: red;
  }
  
  @keyframes slideUp {
    from {
      transform: translate(-50%, 100%); /* Start below the screen */
      opacity: 0;
    }
    to {
      transform: translate(-50%, 0); /* Centered horizontally, visible */
      opacity: 1;
    }
  }
  
  @keyframes slideDown {
    from {
      transform: translate(-50%, 0); /* Centered horizontally, visible */
      opacity: 1;
    }
    to {
      transform: translate(-50%, 100%); /* Move below the screen */
      opacity: 0;
    }
  }
  
  .toast.hide {
    animation: slideDown 0.5s ease forwards;
  }
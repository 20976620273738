.horizontal-session-list-container {
  display: flex; /* Ensures that the buttons and the card list are laid out horizontally */
  align-items: center; /* Vertically centers the buttons and the card list */
  justify-content: center; /* Centers the entire content horizontally if needed */
  /* overflow: hidden;  */
  width: 100%; /* Takes the full width of the parent container */
}

.scroll-container {
  display: flex;
  flex-wrap: nowrap; /* Prevents the cards from wrapping onto the next line */
  width: 100%; /* Adjust this based on your button size to ensure it doesn't push off screen */
  margin: 0 20px; /* Provides spacing between the buttons and the card list */
}

.session-card-wrapper {
  flex: 0 0 auto; /* This prevents the cards from stretching. Use 'auto' to maintain the card width */
  width: 380px; /* Width of each card */
  margin-right: 10px; /* Margin between cards */
}

.thumbnail-wrapper {
    position: relative; /* Needed for absolute positioning of the pseudo-element */
    background-color: white;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border: 1px solid lightgrey;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 400px;
    display: flex;
    background-size: cover;
}

.thumbnail-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px; /* Match the border-radius of the container */
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.9) 0%, 
        rgba(0, 0, 0, 0) 40%
    );
    pointer-events: none; /* Allows interaction with elements underneath */
}
.info-wrapper {
    position: relative; /* Needed for absolute positioning of the pseudo-element */
    background-color: white;
    padding:10px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid lightgrey;
    border-top-width:0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}
h1.time {
    font-size:2.5em;
    font-weight: 600;
}